import { Box, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { useState } from 'react';
import { AspectRatio } from 'src/components/core/aspect-ratio';
import Free from 'src/components/core/casino-icons/free';
import Jackpot from 'src/components/core/casino-icons/jackpot';
import Multiplier from 'src/components/core/casino-icons/multiplier';
import Volatility from 'src/components/core/casino-icons/volatility';
import { generatePath } from 'src/utils/libs/react-router-dom';
import { CasinoGame } from 'src/utils/types/casino';
import { useErrorStyles, useStyles } from './styles';
import { MyLink } from 'src/components/core/mine/my-link';
import { getGameThumbnail } from 'src/utils/helpers/casino';
import { Paths } from 'src/utils/constants/path';

interface FallbackImageProps {
  className?: string;
  data?: CasinoGame;
}

const DefaultFallbackImage: React.FCC<FallbackImageProps> = ({
  data,
  className,
}) => {
  const { classes, cx } = useErrorStyles();

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.top}>
        <div className={cx(classes.triangle, classes.triangle2)} />
        <div className={cx(classes.triangle, classes.triangle1)} />
      </div>

      {data && (
        <div className={cx(classes.bottom, 'root-hover-hidden')}>
          <Typography>{data.title}</Typography>
          <Typography className={classes.subtitle}>
            {data.producerName || data.producer}
          </Typography>
        </div>
      )}
    </div>
  );
};

interface CasinoThumbnailProps {
  data?: CasinoGame;
  FallbackComponent?: React.FCC;
  FallbackProps?: Partial<FallbackImageProps>;
  className?: string;
  disableHover?: boolean;
  showIcons?: boolean;
}

const CASINO_IMG_RATIO = 1;

const BLUR_DATA_URL =
  'data:image/webp;base64,UklGRr4DAABXRUJQVlA4WAoAAAAgAAAAuQAAuQAASUNDUMgBAAAAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADZWUDgg0AEAANAQAJ0BKroAugA+7XaxVamnJCMgSfEwHYlnbt3V3m0ef/Msn6ACTiajd+mgRTy3GPNqcQIX1iGQP55Dz/nkykyr0jkFL9d6bQrcVApcsssa9/E3b9/z5MHt9hEM0cr7///48Ba6d+pb9fw2Wy7f6LhqjwvZV9HCi3luS39je1VQMWF/if9NbPoy05GaH1QAAP7ocae/tT8rPd9935t5G30c5gVgWQap4bSJyWghfS0uGvYPYGvhVUiMthRlGaLGArUBfXVK48tUec5rGCfUWo/ySUmGPZjqovTzr6H8CnlNPKhbwb3p389//hNrHhTMcA452SVwQ5Pnusu7MQAJU3ZzEZmbOX0Ja8f5d7pPiDctmolCOhZtlLWV/T5seOMiwW6w/gDS4oPdvLsHkxfuPNVmc0OrWW+optakH7b/iCuK2x93n9W7ypH/3YceSzb43vaBTWH06T0nXnxdXYb1wlJ4ikLGTV8gosGet9G9p4mMu5FURjBFTRpL6hfB9PUYYLCWYqJmXlcPKAzV1HHHOZVYV2HITiHhxgcuDAAXtUwt7T+wrlmccZ1NN4UIczB4evGkjuYNxa7HLwLGTC59KOOM+6FcFirFM8J2WjEigAAA';
export const CasinoThumbnail: React.FCC<CasinoThumbnailProps> = (props) => {
  const {
    data,
    FallbackComponent = DefaultFallbackImage,
    FallbackProps,
    className,
    disableHover = false,
    showIcons = false,
  } = props;

  const _FallbackProps = FallbackProps ?? { data, className };

  const { classes, cx } = useStyles();
  const [error, setError] = useState<boolean>(false);

  if (!data) return null;

  const src = getGameThumbnail(data.identifier);

  const redirectPath = generatePath(Paths.MetaCasino, {
    category: data.categoryConfig,
    gameId: data.identifier,
  });

  const thumbnail = (
    <>
      <Image
        onError={() => setError(true)}
        width={300}
        height={300}
        className={classes.image}
        loading="lazy"
        src={src}
        alt={data.identifier}
        placeholder="blur"
        blurDataURL={BLUR_DATA_URL}
      />

      {error && <FallbackComponent {..._FallbackProps} />}
    </>
  );

  if (disableHover) {
    return (
      <AspectRatio
        disableWrapped
        ratio={CASINO_IMG_RATIO}
        wrapperClassName={cx(classes.root, className)}>
        {thumbnail}
      </AspectRatio>
    );
  }

  return (
    <MyLink href={redirectPath} underline="none" color="inherit">
      <AspectRatio
        disableWrapped
        ratio={CASINO_IMG_RATIO}
        wrapperClassName={cx(classes.root, className)}>
        {thumbnail}
      </AspectRatio>
      <Box
        sx={{
          my: 0.5,
          display: 'table',
          tableLayout: 'fixed',
          width: '100%',
        }}>
        <Typography noWrap>{data.title}</Typography>
      </Box>

      <Typography noWrap color={'custom.content.2'} fontSize={11}>
        {data.producerName ? data.producerName : data.provider}
      </Typography>
      {showIcons && (
        <Stack spacing={1} mt={1} direction="row">
          <Multiplier multiplier={data?.multiplier} />
          <Jackpot hasJackpot={data?.hasJackpot} />
          <Free hasFreeSpins={data?.hasFreespins} />
          <Volatility volatilityRating={data?.volatilityRating} />
        </Stack>
      )}
    </MyLink>
  );
};
