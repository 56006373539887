import { Trans } from '@lingui/macro';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import XIcon from 'src/assets/icons/x.svg';
import { PresicavHv } from 'src/assets/styles/fonts';

interface MultiplierProps {
  multiplier: number | undefined;
  width?: number | string;
  height?: number | string;
}

const suffixFormatter = (num: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.findLast((item) => num >= item.value);
  return item
    ? {
        number: BigNumber(num / item.value)
          .toFixed(digits, BigNumber.ROUND_DOWN)
          .replace(regexp, ''),
        symbol: item.symbol,
      }
    : null;
};

export const Multiplier = ({
  multiplier,
  width = '30px',
  height = '24px',
}: MultiplierProps) => {
  if (!multiplier) return null;
  const numberSuffix = suffixFormatter(multiplier, 0);

  return (
    <Tooltip
      title={<Trans id="casino_icons.multiplier">Win multiplier </Trans>}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          border: 'solid 1px',
          borderColor: 'custom.content.1',
          borderRadius: 1,
          padding: '1px 2px',
          width,
          height,
          overflow: 'hidden',
        }}>
        <Typography
          color="custom.content.1"
          sx={{
            fontSize: 7,
            fontWeight: 900,
            lineHeight: '9px',
            ...PresicavHv.style,
          }}>
          {numberSuffix?.number}
          <Box component="span" sx={{ fontSize: 4, lineHeight: '5px' }}>
            {numberSuffix?.symbol}
          </Box>
        </Typography>

        <XIcon />
      </Stack>
    </Tooltip>
  );
};

export default Multiplier;
