import { Trans } from '@lingui/macro';
import { Tooltip } from '@mui/material';
import FreeIcon from 'src/assets/icons/free.svg';

interface FreeProps {
  hasFreeSpins: boolean | undefined;
  width?: number | string;
  height?: number | string;
}

const Free = ({ hasFreeSpins, width = 24, height = 24 }: FreeProps) => {
  if (!hasFreeSpins) return null;

  return (
    <Tooltip
      title={<Trans id="casino_icons.free_spins"> Freespins feature</Trans>}>
      <FreeIcon width={width} height={height} />
    </Tooltip>
  );
};

export default Free;
