import { Trans } from '@lingui/macro';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { getGamesByCategory } from 'src/api/casino';
import { SortGameEnum } from 'src/components/modules/casino/producer-sorter';
import { getGamesByCategoryKey } from 'src/utils/helpers/swr';
import { DisplayType } from 'src/utils/types/admin';
import { CasinoGame, GetGamesParams } from 'src/utils/types/casino';
import { IPagination } from 'src/utils/types/response';
import useSWRInfinite, {
  SWRInfiniteConfiguration,
  SWRInfiniteResponse,
  unstable_serialize,
} from 'swr/infinite';

type UseGamesProps = Partial<
  GetGamesParams & { favorite?: boolean; sort?: SortGameEnum }
>;

type UseGameOpts = {
  enable?: boolean;
  fetcher?: (params: UseGamesProps) => Promise<IPagination<CasinoGame>>;
  toast?: boolean;
} & SWRInfiniteConfiguration<IPagination<CasinoGame>>;

export interface UseGameReturns
  extends SWRInfiniteResponse<IPagination<CasinoGame, any>, any> {
  hasMore: boolean;
  loadMore: () => Promise<IPagination<CasinoGame, any>[] | undefined>;
  totalCount: number;
}

const notiKey = 'games_not_accessible';
export const useSwrCasinoGames = (
  params: UseGamesProps,
  _opts?: UseGameOpts,
): UseGameReturns => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { fetcher, toast = false, ...withoutFetcher } = _opts ?? {};
  const opts = {
    ...withoutFetcher,
    enable: withoutFetcher?.enable ?? true,
  };

  const swr = useSWRInfinite(
    opts.enable
      ? getGamesByCategoryKey({ ...params, status: DisplayType.ACTIVE })
      : _.constant(null),
    async ([, params]) => {
      return (fetcher ?? getGamesByCategory)(params);
    },
    { revalidateFirstPage: false, revalidateAll: false, ...opts },
  );

  const { data, setSize } = swr;

  const { total_count: totalCount, hasMore } = swrHasMore(data);
  const loadMore = () => setSize((size) => size + 1);

  const query = useMemo(
    () => unstable_serialize(getGamesByCategoryKey(params)),
    [params],
  );

  useEffect(() => {
    if (!toast || fetcher) return;
    if (!!data && totalCount <= 0) {
      const timer = setTimeout(() => {
        enqueueSnackbar(
          <Trans id="games_not_accessible">
            Games are available for play, but are not accessible in your current
            location
          </Trans>,
          {
            variant: 'error',
            autoHideDuration: null,
            hideIconVariant: true,
            preventDuplicate: true,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            key: notiKey,
          },
        );
      }, 1000);
      return () => {
        closeSnackbar(notiKey);
        clearTimeout(timer);
      };
    }
  }, [totalCount, query, data]);

  return {
    hasMore,
    loadMore,
    totalCount,
    ...swr,
  };
};

export function swrHasMore<T>(data: IPagination<T>[] | undefined) {
  const total_count = _.first(data)?.meta?.total_count ?? 0;
  let current_count = 0;

  for (const i of data ?? []) {
    if (i) {
      current_count += i?.items?.length ?? 0;
    }
  }

  const hasMore = current_count < total_count;
  return { hasMore, total_count };
}
