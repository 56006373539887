import { Box } from '@mui/material';
import { useSearchParams } from 'next/navigation';
import React, { useEffect } from 'react';
import { Footer } from 'src/components/modules/footer';
import { DetailDialog } from 'src/components/modules/leaderboard/sport/detail-dialog';
import { MultiBetDrawer } from 'src/components/modules/multi-bet-drawer';
import { NavBar } from 'src/components/modules/navbar';
import { BottomNav } from 'src/components/modules/navbar/bottom-nav';
import { DrawersOrLogin } from 'src/components/templates/basic-template/drawer-or-login';
import { Main } from 'src/components/templates/basic-template/main';
import { clientCookie } from 'src/utils/helpers/headers/headers.client';

export const BasicTemplate: React.FCC = ({ children }) => {
  const searchParams = useSearchParams();

  useEffect(() => {
    const btag = searchParams.get('btag');
    // if there is a btag in the url , store it in the cookies
    if (btag) {
      // Check if a cookie already exists
      const storedBtag = clientCookie.get('btag');
      // if a btag is stored, ignore the new one
      if (storedBtag) {
        return;
      }
      // Store the btag in the cookies for 30 days
      clientCookie.set('btag', btag, { maxAge: 30 * 24 * 60 * 60 });
    }
  }, [searchParams]);

  return (
    <Main>
      <DetailDialog />
      <NavBar />
      <DrawersOrLogin />
      <Box component="main" sx={{ py: 3, flexGrow: 1 }}>
        {children}
      </Box>
      <BottomNav />
      <Footer />

      <MultiBetDrawer />
    </Main>
  );
};
