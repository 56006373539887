import { Trans } from '@lingui/macro';
import { Tooltip } from '@mui/material';
import HighVolatilityIcon from 'src/assets/icons/high-volatility.svg';
import LowVolatilityIcon from 'src/assets/icons/low-volatility.svg';
import MediumVolatilityIcon from 'src/assets/icons/medium-volatility.svg';

interface VolatilityProps {
  volatilityRating: string | undefined;
  width?: number | string;
  height?: number | string;
}

const Volatility = ({
  volatilityRating,
  width = 24,
  height = 24,
}: VolatilityProps) => {
  if (volatilityRating === 'high' || volatilityRating === 'very-high')
    return (
      <Tooltip
        title={
          <Trans id="casino_icons.high_volatility">High volatility</Trans>
        }>
        <HighVolatilityIcon width={width} height={height} />
      </Tooltip>
    );

  if (volatilityRating === 'medium' || volatilityRating === 'medium-high')
    return (
      <Tooltip
        title={
          <Trans id="casino_icons.medium_volatility">Medium volatility</Trans>
        }>
        <MediumVolatilityIcon width={width} height={height} />
      </Tooltip>
    );

  if (volatilityRating === 'low-medium' || volatilityRating === 'low')
    return (
      <Tooltip
        title={<Trans id="casino_icons.low_volatility">Low volatility</Trans>}>
        <LowVolatilityIcon width={width} height={height} />
      </Tooltip>
    );

  return null;
};

export default Volatility;
