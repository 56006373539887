import { Trans } from '@lingui/macro';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import _ from 'lodash';
import React from 'react';
import { useEffectOnce } from 'react-use';
import TokenAmount from 'src/components/core/token-amount';
import { usePlayingCasino } from 'src/hooks/use-playing-casino';
import { toUsd } from 'src/hooks/use-stake-coin';
import { stakeCoinAtom } from 'src/store/auth';
import { myMoneyAtom } from 'src/store/bet-slip';
import {
  hideBalanceAtom,
  stakeCoinBalanceAtom,
  stakeCoinPriceAtom,
} from 'src/store/layout';
import { totalBalanceInUsdAtom, userBrokeAtom } from 'src/store/wallet';
import { CRYPTO_DP, DEFAULT_COIN } from 'src/utils/constants/common-constant';
import { getIconSrc } from 'src/utils/constants/icon-coins';
interface SeeBalanceProps {}

export const SeeBalance: React.FCC<SeeBalanceProps> = () => {
  const hideBalance = useAtomValue(hideBalanceAtom);
  const balance = useAtomValue(stakeCoinBalanceAtom);
  const balancePrices = useAtomValue(stakeCoinPriceAtom);
  const totalWalletBalanceUSD = useAtomValue(totalBalanceInUsdAtom);

  const [openMyMoney, setOpenMyMoney] = useAtom(myMoneyAtom);

  const broke = useAtomValue(userBrokeAtom);
  const setStakeCoin = useSetAtom(stakeCoinAtom);

  useEffectOnce(() => {
    if (broke) {
      setStakeCoin(DEFAULT_COIN);
    }
  });

  const isGame = usePlayingCasino();

  const coinIcon = (
    <Box
      component="img"
      src={getIconSrc(balance.symbol)}
      alt={balance.symbol}
      width={20}
      height={20}
    />
  );

  return (
    <Button
      variant="outlined"
      sx={{
        borderRadius: '3px',
        px: 1,
        py: '6px',
        height: 38,
        ...(!openMyMoney && {
          borderColor: 'custom.content.3',
          bgcolor: 'custom.bg.2',
        }),
      }}
      onClick={() => setOpenMyMoney((prev) => !prev)}
      startIcon={coinIcon}>
      {hideBalance ? (
        <Typography component="span">*.*****</Typography>
      ) : (
        <Stack spacing="2px" alignItems="flex-start">
          <Typography noWrap fontSize={12} lineHeight={1} fontWeight={500}>
            {isGame ? (
              <Trans id="header.casino.played">Inplay</Trans>
            ) : (
              <TokenAmount
                amount={balance.balance_available}
                symbol={balance.symbol}
                decimal={CRYPTO_DP}
              />
            )}
          </Typography>
          {totalWalletBalanceUSD.gt(0) && (
            <Typography
              noWrap
              fontSize={10}
              lineHeight={1}
              color="custom.content.5">
              ($
              {toUsd(balance.balance_available, balancePrices)})
            </Typography>
          )}
        </Stack>
      )}
    </Button>
  );
};
