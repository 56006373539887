import { Trans } from '@lingui/macro';
import { Tooltip } from '@mui/material';
import DollarIcon from 'src/assets/icons/dollar.svg';

interface JackpotProps {
  hasJackpot: boolean | undefined;
  width?: number | string;
  height?: number | string;
}

const Jackpot = ({ hasJackpot, width = 24, height = 24 }: JackpotProps) => {
  if (!hasJackpot) return null;
  return (
    <Tooltip title={<Trans id="casino_icons.jackpot">Jackpot feature </Trans>}>
      <DollarIcon width={width} height={height} />
    </Tooltip>
  );
};

export default Jackpot;
